import React, { useState } from "react"
import { LegacyCard, Icon, Text, LegacyStack, Box } from "@shopify/polaris"
import { ChevronUpIcon, ChevronDownIcon } from "@shopify/polaris-icons"
import CardDivider from "./cardDivider"
function CardWrapper(props) {
  const { children, title, subtitle } = props
  const [cardCollapse, setCardCollapse] = useState(true)

  return cardCollapse ? (
    <LegacyCard sectioned>
      <div onClick={() => setCardCollapse(false)}>
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <Text as="h2" variant="headingMd">
              {title}
            </Text>
            <Text tone="subdued">{subtitle}</Text>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <Icon source={ChevronUpIcon} />
          </LegacyStack.Item>
        </LegacyStack>
      </div>
      <CardDivider />
      <Box paddingBlockStart="400">
        {children}
      </Box>
    </LegacyCard>
  ) : (
    <LegacyCard sectioned>
      <div onClick={() => setCardCollapse(true)}>
        <LegacyStack alignment="center">
          <LegacyStack.Item fill>
            <Text as="h2" variant="headingMd">
              {title}
            </Text>
            <Text tone="subdued">{subtitle}</Text>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <Icon source={ChevronDownIcon} />
          </LegacyStack.Item>
        </LegacyStack>
      </div>
    </LegacyCard>
  )
}

export default CardWrapper
